import CarouselHeader from "components/CarouselHeader"
import SEO from "components/SEO"
import ShopHeader from "components/ShopHeader"
import ShopTiles, { formatStrapiTile } from "components/Strapi/ShopTiles"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding-top: 0px !important;
  > :first-child > :first-child {
    padding-top: 50px;
  }

  .title {
    position: relative;
    font-family: "Rammetto One";
    z-index: 1000;
    max-width: 1100px;
    margin: 0 auto;
    color: #fff;
    padding: 20px 0;

    text-shadow: 0 3px 0 #afafaf, 0 5px 0 rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 900px) {
    .carousel-header .slide {
      padding-top: 130px;
      .slide-picture {
        justify-content: flex-start !important;
      }
    }
  }
`

const Content = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
`

const ShopHome = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      strapiShopPage {
        CarouselHero {
          CarouselHeroElements {
            id
            title
            button {
              targetUrl
              value
              theme
            }
            image {
              id
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 550, quality: 70)
                }
              }
            }
            backgroundImage {
              id
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
        ShopPageTiles {
          id
          type
          backgroundImage {
            id
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 1100, quality: 90)
              }
            }
          }
          image {
            id
            url
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 550, quality: 90)
              }
            }
          }
          title
          button {
            id
            value
            theme
            targetUrl
          }
          BulleltItems {
            id
            value
          }
        }
        SEO {
          title
          description
          url
          noIndex
          image {
            url
          }
          canonical
        }
      }
    }
  `)

  const slides = data.strapiShopPage.CarouselHero.CarouselHeroElements.map(
    element => ({
      id: element.id,
      backgroundImage: element.backgroundImage,
      image: element.image,
      title: element.title,
      button: element.button
        ? {
            label: element.button.value,
            target: element.button.targetUrl,
            external: element.button.targetUrl[0] !== "/",
          }
        : null,
      BulletItems: element.BulletItems,
      richContent: element.richContent,
    })
  )

  const tiles = data.strapiShopPage.ShopPageTiles.map(s => formatStrapiTile(s))

  const DEFAULT_SEO = {
    description:
      data.strapiShopPage.SEO?.description ||
      "Children draw their own magical characters that go with them through the Night Zoo, developing and growing their creative writing skills.",
    image:
      data.strapiShopPage.SEO?.image?.url ||
      "https://cdn.nightzookeeper.com/strapi/media/share_picture_976eb57814/share_picture_976eb57814.jpg",
    title: data.strapiShopPage.SEO?.title || "Shop",
    noIndex: data.strapiShopPage.SEO?.noIndex || false,
    url:
      data.strapiShopPage.SEO?.url || `${data.site.siteMetadata.siteUrl}/shop`,
    canonical: data.strapiShopPage.SEO?.canonical || null,
  }

  return (
    <Wrapper>
      <SEO
        {...DEFAULT_SEO}
        {...{
          ...data.strapiShopPage.SEO,
          image: data.strapiShopPage.SEO?.image.url,
        }}
        titleTemplate="%s | Night Zookeeper"
      />
      <CarouselHeader slides={slides}>
        <div className="title">
          <h1>Night Zookeeper Shop</h1>
        </div>
      </CarouselHeader>
      <ShopHeader />
      <Content>
        <ShopTiles tiles={tiles} />
      </Content>
    </Wrapper>
  )
}

export default ShopHome
